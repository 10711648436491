.mrcms-admin-bar-on {
    .main-nav,
    .offcanvas-menu {
        top: 1.875rem;
    }
}

.main-nav {
    nav {
        box-shadow: 0 0 0.789rem 0 rgba(0, 0, 0, 0.1);
    }

    .btn-primary-mobile {
        padding: 0.54rem 0.915rem;
    }
    .btn-offcanvas{
        padding: 0.672rem 1.2828rem;
    }

    .btn-outline-dark {
        padding: 0.472rem;
    }
}

@include media-breakpoint-up(md) {
    .main-nav {
        nav {
            padding: 1.574rem 0;
        }
        .navbar-brand img{
            width: 10rem;
        }
        .nav-link {
            font-size: 0.737rem; //14
            color: $secondary;
            &:hover {
                color: $primary;
            }
        }
    }

    // nav dropdown hover
    .nav-item {
        &:hover {
            .dropdown-menu {
                opacity: 1;
                visibility: visible;
            }
        }

        .dropdown-menu {
            display: block;
            min-width: 17.842rem;
            opacity: 0;
            visibility: hidden;
            -webkit-box-shadow: 0 0 0.625rem #00000029;
            box-shadow: 0 0 0.625rem #00000029;
        }

        .dropdown-item {
            color: $primary;

            &:hover,
            &:active {
                background-color: transparent;
                color: #12223c;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .main-nav {
        .navbar-brand img{
            width: 14.537rem;
        }
        .nav-link {
            font-size: 0.895rem; //17
        }
        .btn-outline-dark {
            padding: 0.672rem 1.035rem;
        }
    }
}

// mobile menu only
@keyframes hover {
    50% {
        transform: translateX(0.526rem);
    }
}

#mobile-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
    z-index: 9998;
    background-color: rgba(0, 0, 0, 0.75);
    display: none;
}

.offcanvas-menu {
    z-index: 9999;
    top: 0;
    left: -30rem;
    transition: 0.3s ease;
    // transition: opacity 200ms, display 200ms;
    width: 100%;
    // display: none;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    .mobile-body {
        transition: all .3s;
        background: #FFFFFF;

        >div {
            label {
                width: 2.938rem;
                height: 2.938rem;
                cursor: pointer;
                transition: 0.3s ease;
                transform: translate(13px, -13px);

                span,
                span:before,
                span:after {
                    background: $primary;
                }
            }
        }

        >ul {

            li {
                cursor: pointer;
                transform: translateX(-30px);
                opacity: 0;
                transition: 0.4s ease;
                >.nav-link{
                    font-size: 1.053rem;
                    color: $primary;
                }
            }
        }
        .mobile-list{
            line-height: 1.53;
            li {
                font-size: 0.789rem;
                &:hover {
                    animation: hover 0.5s;
                }
                a{
                    text-decoration: none;
                }
            }
        }
    }
}

.main-nav {
    .toggle-close span {
        transform: translate(1.188rem, 2.125rem);
    }

    .container-lg>label {
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;
        transition: 0.3s ease;
        display: flex;
        align-items: center;
        z-index: 9999;
    }

    .container-lg label {

        span,
        span::before,
        span::after {
            transition: background 0.3s, transform 0.3s;
            content: "";
            position: absolute;
            width: 1.05rem;
            height: 0.15rem;
            background: $primary;
        }

        span::before {
            transform: translateY(-0.75rem);
        }

        span::after {
            transform: translateY(0.75rem);
        }
    }

    .container-lg>.toggle-open {
        width: 1.875rem; //30
        height: 1.875rem;
    }

    .container-lg .toggle-open {

        span,
        span::before {
            width: 1rem;
        }

        span,
        span::before,
        span::after {
            height: 0.158rem;
            border-radius: 0.526rem;
        }

        span::before {
            transform: translateY(-0.4rem);
        }

        span::after {
            transform: translateY(0.4rem);
            width: 1rem;
        }
    }

    input[type="checkbox"] {
        display: none;

        &:checked {
            ~label {
                opacity: 0;
                pointer-events: none;
            }

            ~.offcanvas-menu {
                left: 0;
                // display: block;
                position: fixed;
                visibility: visible;
                opacity: 1;
                label span {
                    background: transparent;

                    &:before {
                        transform: rotate(-45deg);
                    }

                    &::after {
                        transform: rotate(45deg);
                    }

                    &:before,
                    &:after {
                        transition-delay: 0.2s;
                    }
                }

                ul li {
                    opacity: 1;
                    transform: translateX(0px);

                    &:nth-child(1) {
                        transition-delay: 0.1s;
                    }

                    &:nth-child(2) {
                        transition-delay: 0.2s;
                    }

                    &:nth-child(3) {
                        transition-delay: 0.3s;
                    }

                    &:nth-child(4) {
                        transition-delay: 0.4s;
                    }

                    &:nth-child(5) {
                        transition-delay: 0.5s;
                    }

                    &:nth-child(6) {
                        transition-delay: 0.6s;
                    }

                    &:nth-child(7) {
                        transition-delay: 0.7s;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .offcanvas-menu {
        width: 20rem;
    }
}
