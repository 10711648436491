$primary: #2fbcb7;
$secondary: #12223c;
$light: #efefef;

$body-color: #12223c;

//font
$font-size-root: 19px;

$font-family-base: 'Source Sans 3', sans-serif;
$headings-font-family: 'Roboto Slab', serif;
 
//font-weight
$headings-font-weight: 700;

//header font sizes
$h1-font-size: 2.368rem; //45px
$h2-font-size: 1.842rem; //35px
$h3-font-size: 1.053rem; //20px
$h4-font-size: 1rem; //19px
$h5-font-size: 0.895rem; //17px

//Font-sizes
$font-sizes: (
  10: 0.526rem,
  11: 0.579rem,
  12: 0.632rem,
  14: 0.737rem,
  15: 0.789rem,
  16: 0.842rem,
  17: 0.895rem,
  18: 0.947rem,
  19: 1rem,
  20: 1.053rem,
  22: 1.158rem,
  24: 1.263rem,
  25: 1.316rem,
  26: 1.368rem,
  28: 1.474rem,
  30: 1.579rem,
  32: 1.684rem,
  35: 1.842rem,
  38: 2rem,
  40: 2.105rem,
  45: 2.368rem,
  63: 3.316rem,
  67: 3.526rem,
) !default;

//buttons
$btn-border-radius: 0;

//accordion default
$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-icon-transform:      rotate(0deg);

//form
$input-focus-box-shadow: none;
$form-select-focus-box-shadow: none;
$form-select-indicator-color: $primary;

//grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px
);