//Override the default bootstrap theme
@import "../../node_modules/bootstrap/scss/functions";

@import "./overrides";

@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";

@import "../../node_modules/bootstrap/scss/utilities";

//Utilities Merge
@import "./utilitiy_merges";

// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/offcanvas";
@import "../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";

//font
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;600;700&family=Source+Sans+3:wght@400;600;700&display=swap');

// Bootstrap Icons
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

//aos
@import "../../node_modules/aos/src/sass/aos.scss";

@import "./header";
@import "./footer";
@import "./scroll-gallery";

body {
    padding-top: 2.875rem; //54.63px
    font-family: 'Source Sans 3', sans-serif;
}

//font
.font-roboto-slab {
    font-family: 'Roboto Slab', serif;
}

.font-source-sans {
    font-family: 'Source Sans 3', sans-serif;
}

//containers
.container-p {
    padding: 1rem 0;
}

#FAQs, #PCM, #Criteria{ 
    scroll-margin-top: 6.842rem; 
}
#contact-form{
    scroll-margin-top: 20rem;
}

//borders
@mixin short-border($color) {
    content: "";
    width: 3.263rem;
    border-bottom: 0.289rem solid $color;
    display: block;
}

.short-border-start:after,
.short-border-start-1:after {
    margin: 1rem auto 0 0;
}

.short-border-center:after,
.short-border-center-1:after {
    margin: 1rem auto 0;
}

.short-border-green:after {
    @include short-border($primary);
}

.border-bottom-dark {
    border-bottom: 0.053rem solid rgba(255, 255, 255, 0.17);
}
.border-top-dark{
    border-top: 0.053rem solid rgba(255, 255, 255, 0.17);
}

//buttons
.btn-white-green {
    padding: 0.75rem 1.1rem;
    color: #ffffff;
    background-color: $primary;
    border-color: $primary;

    &:hover,
    &:active {
        background: #59c9c5;
        border-color: #59c9c5;
    }
}

.btn-outline-dark {

    &:hover,
    &:active {
        background: #2FBCB7;
        border-color: #2FBCB7;
    }
}

//mailchimp
div#mc_embed_signup{
    background:transparent;
    clear:left;
    font-size: 14px;
    input.button {
        background-color: $primary;
        border: 1px solid $primary;
        border-radius: 1px;
        color: #FFFFFF;
        font-size: 14px;
        &:hover{
            background-color: #FFFFFF;
            color: $primary;
        }
    }
    #mc-embedded-subscribe-form div.mce_inline_error {
        background-color: transparent;
    }
}


//mobile logo
.mobile-body-logo {
    padding: 1.106rem 0;
    img{
        width: 11.821rem;
        height: auto;
    }
}

//page hero
.page-hero {
    height: 39.421rem;
    // overflow: hidden;

    &__text {
        z-index: 3;
    }

    .short-border-green:after {
        margin: 1rem auto 0;
    }

    &__cloud-left {
        left: -18.316rem;
        bottom: -10rem;
        z-index: 2;
    }
}

//remove when implementing CMS (remove hero-* classes too)
.hero-contact {
    background: top / cover no-repeat url('../images/hero-contact-mobile-2.webp');
}

.hero-borrowers {
    background: top / cover no-repeat url('../images/hero-borrowers-mobile-2.webp');
}

.hero-brokers {
    background: top / cover no-repeat url('../images/hero-brokers-mobile-2.webp');
}

.hero-homepage {
    background: top / cover no-repeat url('../images/hero-homepage-mobile-2.webp');
}

//contact/generic form
.contact-form,
.newsletter {
    &__text {
        h2 {
            line-height: 1.73;
        }
    }

    label {
        color: $primary;
    }

    .form-floating>label {
        padding: 0.75rem;
    }

    .form-floating>input.form-control,
    .form-floating>.form-control-plaintext,
    .form-floating>.form-select {
        height: calc(2.527rem + calc(var(--bs-border-width) * 2));
        min-height: calc(2.527rem + calc(var(--bs-border-width) * 2));
    }

    .form-control,
    .form-select {
        border: 1px solid $primary;
    }

    .btn-primary {
        color: white;
        height: 2.632rem; //50px;
        width: 100%;
    }
}

.contact-form {
    padding-bottom: 2.4rem;

    .form-floating>.form-control:focus~label,
    .form-floating>.form-control:not(:placeholder-shown)~label,
    .form-floating>.form-select~label {
        color: rgba(47, 188, 183, 0.65);
    }

    .form-floating>.form-select {
        padding: 1rem 0.75rem 0;
    }

    textarea.form-control {
        height: 10.174rem; //193.3
    }

    option:hover {
        background-color: $primary;
    }

    .form-select {
        background-position: right 0.75rem center;
        background-size: 0.947rem;
    }
}

//borrowers
//rates
.rates {
    padding: 2rem 0;

    &__percent {
        background: #1b283c;
        border: 0.263rem solid $primary;
        width: 10rem;
        height: 10rem;

        p {
            line-height: 1.07;
            font-size: 0.947rem;
            margin: 0;

            &:nth-of-type(2) {
                font-size: 2.368rem;
                line-height: 0.55;
                margin: 1rem 0;
            }
        }
    }

    &__list {
        line-height: 1.58;
        font-size: 0.789rem;
        padding-left: 1rem;
        ::marker {
            color: $primary;
            font-size: 0.75rem;
        }
    }
}

//accordion
.accordion-item,
.accordion-button {

    &:not(.collapsed),
    &:focus {
        box-shadow: none;
    }
}

.faq-accordion {
    .accordion-item {
        border: solid 1px #e0e0e0;
    }

    .accordion-button {
        color: $primary;
        padding: 0.691rem 0.75rem;
        font-family: 'Source Sans 3', sans-serif;

        &:not(.collapsed) {
            color: #FFF;
            background-color: $secondary;
        }

        &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%232fbcb7' class='bi bi-caret-right-fill' viewBox='0 0 16 16'%3E%3Cpath d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z'/%3E%3C/svg%3E");
            background-size: 0.842rem;
            order: -1; //swap order
            margin-left: 0;
            margin-right: 1rem;
            transform-origin: 0.421rem 0.632rem;
            transform: translate(0.263rem, 0.211rem);
        }

        &[aria-expanded="true"] {
            &:after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23FFFFFF' class='bi bi-caret-right-fill' viewBox='0 0 16 16'%3E%3Cpath d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z'/%3E%3C/svg%3E");
                transform: rotate(90deg);
            }
        }
    }

    .accordion-body {
        padding: 2rem 3rem;
        line-height: 1.58;
        background: #f7f7f7;
    }
}

//interest rates banner
.interest-rates-banner {
    height: 24.716rem;
}

//ltvs
.ltv {
    padding: 2rem 0;

    &__wrapper {
        height: 3.211rem;
    }

    &__text {
        height: 100%;

        div {
            &:first-child {
                &::after {
                    content: '\3A';
                    margin-right: 0.25rem;
                }
            }
        }
    }
}

//criteria
.criteria {
    &__wrapper {
        border-top: 1px solid #e0e0e0;
        padding: 1rem 0;
        line-height: 1.58;

        ul li {
            margin-bottom: 1.15rem;
        }
    }
}

//bp down
@include media-breakpoint-down(md) {
    .ltv__wrapper {
        background: $secondary !important;
    }
}

//bp up
@include media-breakpoint-up(sm) {
    .interest-rates-banner {
        height: 26.205rem;
    }

    .page-hero {

        //remove when implementing CMS (remove hero-* classes too)
        &.hero-contact {
            background: top / cover no-repeat url('../images/hero-contact.webp');
        }

        &.hero-borrowers {
            background: top / cover no-repeat url('../images/hero-borrowers.webp');
        }

        &.hero-brokers {
            background: top / cover no-repeat url('../images/hero-brokers.webp');
        }

        &.hero-homepage {
            background: top / cover no-repeat url('../images/hero-homepage.webp');
        }
    }
}

@include media-breakpoint-up(md) {
    body {
        padding-top: 5.646rem; //107.28px;
    }

    .container-p {
        padding: 2.1rem 0;
    }

    .short-border-start-1:after {
        margin: 1.65rem auto 0 0;
    }

    .short-border-center-1:after {
        margin: 1.65rem auto 0;
    }

    //heroi
    .page-hero {
        height: 45.684rem;

        &__text {
            transform: translateY(-3.947rem);
        }

        .short-border-green:after {
            margin: 1rem auto 0 0;
        }
    }

    //buttons
    .btn-white-green {
        padding: 0.672rem 1.283rem;
        color: #ffffff;
        background-color: transparent;
        border-color: #ffffff;

        &:hover,
        &:active {
            background: #ffffff;
            color: #000;
            border-color: #ffffff;
        }
    }

    //contact form
    .contact-form {
        .form-floating>label {
            padding: 1rem 1.75rem;
        }

        .btn-primary {
            width: auto;
        }

        textarea.form-control {
            height: 8.947rem; //170
            padding-left: 1.6rem;
        }

        .form-floating>.form-select,
        .form-floating>input.form-control {
            height: calc(3.579rem + calc(var(--bs-border-width) * 2));
            min-height: calc(3.579rem + calc(var(--bs-border-width) * 2)); //70
            padding: 1.625rem 2.25rem 0.625rem 1.6rem;
        }

    }

    //newsletter
    .newsletter {
        margin-top: 5rem;

        &__text {
            h2 {
                line-height: 1.41;
            }
        }

        &__img img {
            transform: translateY(-10rem);
        }

        // &__form {
        //     transition: border 0.3s;
        //     border-bottom: 0.053rem solid $primary;

        //     &:hover {
        //         border-color: #b7b7b7;
        //     }
        // }

        //remove bg from desktop label
        .form-control:focus,
        .form-floating>.form-control:focus~label:after,
        .form-floating>.form-control:not(:placeholder-shown)~label:after {
            background-color: transparent;
        }

        .form-floating>.form-control:focus~label,
        .form-floating>.form-control:not(:placeholder-shown)~label {
            transform: scale(.85)translateY(-0.5rem);
        }

        .btn-primary,
        .form-control {
            background-color: transparent;
            border: 0;
        }

        label {
            color: #b7b7b7;
            text-transform: uppercase;
        }

        .btn-primary {
            color: $primary;
            width: 100%;
            height: 100%;

            &:hover {
                color: #b7b7b7;
            }
        }
    }

    //rates
    .rates {
        padding: 5.3rem 0;

        &__percent {
            padding: 1rem;
            width: 16.158rem;
            height: 16.158rem;

            p {
                font-size: 1.474rem;
                line-height: 1.07;

                &:nth-of-type(2) {
                    font-size: 5.105rem;
                    line-height: 0.67;
                    margin: 1.2rem 0;
                }
            }
        }

        &__list {
            line-height: 1.58;
            font-size: 1rem;
            padding-left: 2rem;
            ::marker {
                color: $primary;
                font-size: 0.75rem;
            }
        }
    }

    //ltvs
    .ltv {
        padding: 2.1rem 0 7rem;

        &__wrapper {
            max-width: 100%;
            height: 16.158rem;
            background-size: contain;

            .inner-border {
                height: inherit;
                border: 1.053rem solid transparent;
                box-shadow: inset 0px 0px 0px 1px $primary;
                box-sizing: border-box;
                z-index: 2;
                opacity: 1;
            }
        }

        &__text {
            position: absolute;
            width: 85%;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;

            div {
                &:first-child {
                    &::after {
                        content: none;
                    }
                }
            }
        }

        &__text .short-border-green:after {
            border-bottom: 0.316rem solid #2fbcb7; //5px
            width: 3.063rem;
            margin: 0 auto 0.5rem;
        }

        &__overlay {
            background-color: $secondary;
            opacity: 0.85;
            inset: 0;
            z-index: 1;
            cursor: pointer;
        }
    }

    //criteria
    .criteria {
        &__wrapper {
            border-bottom: 1px solid #e0e0e0;
            padding: 2.2rem 0 1rem;

            ul li {
                margin-bottom: 1.6rem;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    body {
        padding-top: 6.419rem; //121.97px;
    }

    .container-p-lg {
        padding: 3.7rem 0;
    }

    .contact-form {
        padding-bottom: 6.6rem;
    }

    //PCM
    .interest-rates-banner {
        .btn-white-green {
            padding: 0.672rem 1.715rem;
        }
    }

    //ltvs
    .ltv {
        &__text {
            div {
                &:first-child {
                    height: 4.474rem;
                }
            }
        }
    }

    //newsletter
    .newsletter {
        height: 29rem;
        &__img img {
            transform: translateY(-5rem);
        }
    }
}

@include media-breakpoint-up(xl) {

    //page hero
    .page-hero__cloud-left {
        left: -11.316rem;
    }

    //newsletter
    .newsletter {
        &__img img {
            transform: translateY(-10rem);
        }
    }
}

@include media-breakpoint-up(xxl) {

    //newsletter
    .newsletter {
        height: 33.2rem;
        margin-top: 12rem;
        &__wrapper {
            transform: translate(-1.263rem, 5rem);
        }

        &__form {
            width: 28.65rem;
            position: absolute;
        }

        &__img img {
            // width: 33.211rem; //631px;
            // height: 32.158rem; //611px;
            width: 35.316rem; //671px;
            height: 34.789rem; //661px;
        }
    }
}
