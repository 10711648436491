.feature{
    .left-content {
        position: relative;
        & > * {
            width: 100%;
        }
    }
    &__count{
        .num{
            font-size: 3.053rem;//58
        }
        span:not(.num){
            font-size: 1.053rem;//20
            position: fixed;
            top: 2.5rem;
            margin-left: 1rem;
        }
    }
    img, picture, video {
        height: auto;
        max-width: 100%;
    }
}

//breakpoint down
@include media-breakpoint-down(lg){
    .feature{
        img, picture, video {
            max-width: 100%;
        }
        .left-content {
            & > * {
                opacity: 1!important;
                visibility: visible!important;
            }
        }
        &__content{
            padding: 1rem;
            transform: translate(0)!important;
            &:nth-of-type(even){
                background: #efefef;
            }
        }
    }
}
//breakpoint up
@include media-breakpoint-up(md){
    .feature{
        padding: 2.1rem 0;
        &__count{
            span:not(.num){
                top: 2.5rem;
                margin-left: 2.6rem;
            }
        }
        &__media:not(:last-child){
            margin-bottom: 4.95rem;
        }
        img, picture, video {
            object-fit: cover;
            width: 100%;
        }
        .short-border-start-1:after {
            margin: 1.95rem auto 1.95rem 0;
        }
    }
    

    
    //gsap trigger
    .content-trigger{
        height: 1px;
        position: relative;
        top: -15rem;
    }
}

@include media-breakpoint-up(lg){
        //text (left side)
        .left-content {
            & > * {
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, 0)!important;
                opacity: 0;
                visibility: hidden;
            }
        }
    .feature{
        padding: 5.45rem 0;
        &__count{
            .num{
                font-size: 7.105rem;//135
            }
            span:not(.num){
                font-size: 1.632rem;//31
            }
        }
        &__media:not(:last-child){
            margin-bottom: 7.95rem;
        }
    }
}
@include media-breakpoint-up(xxl){
    .feature{
        img, picture, video {
            width: 33.842rem;
            height: 43.211rem;
        }
    }
}