footer{
    min-height: 33.474rem;
    background: $secondary;
    a{
        text-decoration: none;
        &:hover > svg{
            opacity: 0.8;
        }
    }
    ul{
        list-style: none;
        padding-left: 0;
    }
    .footer-list{
        line-height: 2;
    }
    section:not(:last-child){
        position: relative;
        z-index: 1;
    }

    .accordion-item,.accordion-button{
        background-color: transparent;
        border: 0;
        &:not(.collapsed){
            box-shadow: none;
            background-color: transparent;
        }
    }
    
    .accordion-flush .accordion-item{
        color: $primary;
        border-bottom: 1px solid rgba(255, 255, 255, 0.17);
        &:last-child{
            border-bottom: 1px solid rgba(255, 255, 255, 0.17);
        }
    }
    .accordion-button{
        color: white;
        background-color: transparent;
        &:not(.collapsed){
            color: white;
        }
        &[aria-expanded="true"]:after{
            transform: rotate(0deg);
        }
        &:after{
            transform: rotate(-90deg);
        }
    }
}

.footer-cloud{
    position: relative;
    bottom: 0;
    z-index: 0;
    img{
        height: auto;
        width: 100%;
    }
}
@include media-breakpoint-up(sm){
    .footer-cloud{
        bottom: -6rem;
        right: 0;
    }
}

@include media-breakpoint-up(md){
    footer{
        min-height: 43.368rem;
        .footer-list{
            line-height: 2.11;
        }
    }
    section.footer-cloud{
        position: absolute;
        bottom: 0;
        right: -16rem;
        img{
            width: 45rem;
        }
    }
}